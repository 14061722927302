import {Injectable} from '@angular/core';
import {
  Action,
  State,
  Selector,
  StateContext, createSelector,
} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {BandStateModel} from './band-state.models';
import {BandStateActions} from "./band-state.actions";
import {
  BandmanagerRestApiBand, BandmanagerRestApiBandStatsDto, BandmanagerRestApiBankaccount,
  BandmanagerRestApiUniform
} from "@digitale-menschen/bandmanager-rest-api";

const STATE_NAME = 'BandState';

@State<BandStateModel>({
  name: STATE_NAME,
  defaults: {
    band: null,
    bankAccount: null,
    uniform: null,
    stats: null,
  },
})
@Injectable()
export class BandState {
  @Selector()
  static band(state: BandStateModel): BandmanagerRestApiBand | null {
    return state.band;
  }

  @Selector()
  static bankAccount(state: BandStateModel): BandmanagerRestApiBankaccount | null {
    return state.bankAccount;
  }

  @Selector()
  static stats(state: BandStateModel): BandmanagerRestApiBandStatsDto | null {
    return state.stats;
  }

  @Selector()
  static uniform(state: BandStateModel): BandmanagerRestApiUniform | null {
    return state.uniform;
  }

  @Action(BandStateActions.SetBand)
  setBand(ctx: StateContext<BandStateModel>, action: BandStateActions.SetBand) {
    ctx.setState(patch<BandStateModel>({
      band: action.band
    }));
  }

  @Action(BandStateActions.SetBankAccount)
  setBankAccount(ctx: StateContext<BandStateModel>, action: BandStateActions.SetBankAccount) {
    ctx.setState(patch<BandStateModel>({
      bankAccount: action.bankAccount
    }));
  }

  @Action(BandStateActions.SetStats)
  setStats(ctx: StateContext<BandStateModel>, action: BandStateActions.SetStats) {
    ctx.setState(patch<BandStateModel>({
      stats: action.stats
    }));
  }

  @Action(BandStateActions.SetUniform)
  setUniform(ctx: StateContext<BandStateModel>, action: BandStateActions.SetUniform) {
    ctx.setState(patch<BandStateModel>({
      uniform: action.uniform
    }));
  }

  /*  static eventById(id: number) {
    return createSelector([EventsState], (state: EventsStateModel): DbrEventEntity | undefined => {
      if (state.events && state.events.length) {
        return state.events.find((item)=> item.id === id);
      } else {
        return undefined;
      }
    });
  }*/

}

